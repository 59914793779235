import { graphql } from 'gatsby'
import React from 'react'

import Breadcrumb from '../../components/breadcrumb'
import FragrancesList from '../../components/fragrances-list'
import Heading from '../../components/heading'
import Layout from '../../components/layout'
import Paragraph from '../../components/paragraph'
import SEO from '../../components/seo'


export const frontmatter = {
  body: 'New Perfumes',
  slug: '/perfumes/new/',
  title: 'New Perfumes',
}

export const query = graphql`
  query NewPageQuery($genderId: PostGraphile_Gender!) {
    ...SiteInfo
    results: postgres {
      fragrances: allFragrancesList(
        condition: { gender: $genderId },
        first: 10,
        orderBy: [ CREATED_DESC, BRAND_ASC, NAME_ASC ],
      ) {
        ...Fragrance
        brand: brandByBrand {
          ...Brand
        }
      }
    }
  }
`

export default ({ data }) => {
  const { fragrances } = data.results
  const { links, meta } = data.site.siteMetadata
  const { entity, genderization } = meta

  const page = 'New'
  const title = `${page} ${entity.singular} Arrivals`
  const keywords = title.split(/\W+/)
  const trail = [
    {
      name: entity.plural,
      slug: links.fragrances,
    },
    {
      name: page,
      slug: links.new,
    },
  ]
  const schema = {
    breadcrumb: trail,
    description: title,
    keywords,
    name: title,
    products: fragrances,
    slug: links.new,
  }

  return (
    <Layout>
      <SEO keywords={keywords} schema={schema} title={title} />
      <Breadcrumb trail={trail} />

      <Heading>{title}</Heading>

      <Paragraph>
        These are the newest {genderization} fragrances to arrive on our site.
      </Paragraph>

      <FragrancesList fragrances={fragrances} />

      <Breadcrumb trail={trail} />
    </Layout>
  )
}
